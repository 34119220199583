import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Check from "@mui/icons-material/Check";
import { JobStatusesArray, JobStatusesMap, TimeframeArray, TimeframeMap } from "helpers/constants/index";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";

import JobActionMenu from "./components/JobActionMenu";
import JobTimelineDialog from "./components/JobTimelineDialog";
import StateInfo from "./components/StateInfo";
import JobCancelDialog from "./components/JobCancelDialog";
import Can from "components/Authorization/Can";
import { JobPermissions } from "helpers/constants/permissions";

class JobHeader extends React.Component {
    static propTypes = {
        jobId: PropTypes.number.isRequired,
        positionsFilled: PropTypes.number.isRequired,
        status: PropTypes.oneOf(JobStatusesArray).isRequired,
        timeframe: PropTypes.oneOf(TimeframeArray).isRequired,
        history: PropTypes.object.isRequired,
        isActive: PropTypes.bool.isRequired,
        canEnable: PropTypes.bool.isRequired,
        canCancel: PropTypes.bool.isRequired,
        canEditDescription: PropTypes.bool.isRequired,
        isEnabling: PropTypes.bool.isRequired,
        onEnableJob: PropTypes.func.isRequired,
        onCancelJob: PropTypes.func.isRequired,
        // onDeleteJob: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showEnableDialog: false,
            showEditDescriptionDialog: false,
            showTimelineDialog: false,
            jobDescription: props.description || "",
        };
    }

    get cancelMessage() {
        const { positionsFilled } = this.props;
        const message = "This job will be cancelled and deleted.";
        if (positionsFilled) {
            return `${message} ${positionsFilled} worker(s) will be notified.`;
        }
        return message;
    }

    // Enable job
    handleEnableJob = () => {
        this.setState({
            showEnableDialog: true,
        });
    };

    hideEnableDialog = () => {
        this.setState({
            showEnableDialog: false,
        });
    };

    handleConfirmEnable = () => {
        const { onEnableJob, jobId, employerId } = this.props
        this.setState(
            {
                showEnableDialog: false,
            }
        )
        onEnableJob(employerId, jobId)
    };

    // Cancel job
    handleCancelJob = () => {
        const { openCancelJobDialog } = this.props;
        openCancelJobDialog();
    };

    handleConfirmCancel = (recurringJobList, jobId) => {
        const { onCancelRecurringJob, isFixedTerm } = this.props;
        onCancelRecurringJob(recurringJobList, jobId)
            .then(() => {
                this.props.history.push(`/jobs/${isFixedTerm ? "multi-shift/overview" : "gig/overview"}`);
            })
    };

    // Edit job description
    handleEditDescription = () => {
        this.setState({
            showEditDescriptionDialog: true,
        });
    };

    hideEditDescription = () => {
        this.setState({
            showEditDescriptionDialog: false,
        });
    };

    handleDescriptionChange = event => {
        this.setState({
            jobDescription: event.target.value,
        });
    };

    handleConfirmEditDescription = () => {
        const { jobDescription } = this.state;
        this.setState(
            {
                showEditDescriptionDialog: false,
            },
            () => this.props.onEditDescription(jobDescription)
        );
    };

    // Timeline Dialog
    openTimelineDialog = () => {
        this.setState({ showTimelineDialog: true });
    };

    closeTimelineDialog = () => {
        this.setState({ showTimelineDialog: false });
    };

    renderEnableButton = () => {
        const { isActive, isEnabling, isBaseJob,canEnable = true } = this.props;

        if (isActive || !isBaseJob) {
            return null;
        }

        const enableButton = (
            <Button
                label="Enable Job"
                icon={<Check />}
                isLoading={isEnabling}
                alignIcon="left"
                disabled={!canEnable}
                onClick={this.handleEnableJob}
            />
        );

        if (!canEnable) {
            return (
                <Tooltip title="Can't enable jobs in the past" placement="left">
                    <div>{enableButton}</div>
                </Tooltip>
            );
        }

        return enableButton;
    };

    render() {
        const { jobId, isFixedTerm, timeframe, status, isActive, isBaseJob, canCancel, canEditDescription, isQueued } = this.props;
        return (
            <Grid container>
                <StateInfo jobId={jobId} timeframe={timeframe} status={status} isActive={isActive} isQueued={isQueued} />
                <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                    <JobActionMenu
                        jobId={jobId}
                        canEdit={!isActive && isBaseJob}
                        canDuplicate={isBaseJob}
                        canCancel={canCancel}
                        canDelete={!isActive && isBaseJob}
                        canEditDescription={canEditDescription}
                        onCancel={this.handleCancelJob}
                        onEditDescription={this.handleEditDescription}
                        openTimelineDialog={this.openTimelineDialog}
                    />
                    <Can perform={JobPermissions.update.activate.value}>
                        {allowed => {
                            if (!allowed) {
                                return null;
                            }
                            return this.renderEnableButton()
                        }}
                    </Can>
                </Grid>
                <Dialog
                    open={this.state.showEnableDialog}
                    title="Are You Sure?"
                    description="The job will become available on Grizzly Force."
                    confirmText="Yes"
                    onClose={this.hideEnableDialog}
                    onConfirm={this.handleConfirmEnable}
                    onCancel={this.hideEnableDialog}
                    alertType="question"
                />
                <Dialog
                    open={this.state.showEditDescriptionDialog}
                    title="Edit Job Description"
                    description="Edit the job description below.  All workers will be notified of the change."
                    confirmText="Update"
                    onClose={this.hideEditDescription}
                    onConfirm={this.handleConfirmEditDescription}
                    onCancel={this.hideEditDescription}
                    alertType="question"
                    icon="assignment"
                // disabled={this.state.jobDescription.length > 500}
                >
                    <TextField
                        id="description"
                        label="Description"
                        placeholder="Use this section to add any additional information that may be helpful to your worker(s)"
                        value={this.state.jobDescription}
                        multiline
                        rows="7"
                        fullWidth
                        onChange={this.handleDescriptionChange}
                        margin="normal"
                    // helperText={
                    //   this.state.jobDescription.length === 0
                    //     ? "500 characters max"
                    //     : `${500 - this.state.jobDescription.length} character(s) left`
                    // }
                    />
                </Dialog>

                <JobCancelDialog isFixedTerm={isFixedTerm} cancelJob={this.handleConfirmCancel} jobId={jobId} cancelMessage={this.cancelMessage} />

                {this.state.showTimelineDialog && (
                    <JobTimelineDialog
                        open={this.state.showTimelineDialog}
                        onClose={this.closeTimelineDialog}
                    />
                )}
            </Grid>
        );
    }
}

const mapState = state => {
    const {
        id: jobId,
        timeframe,
        status,
        isQueued,
        isActive,
        employerId,
        positionsFilled,
        description,
        isFixedTerm
    } = JobDetailSelectors.getJobDetails(state);
    const isBaseJob = JobDetailSelectors.getIsBaseJob(state);

    const canCancel =
        isBaseJob &&
        timeframe !== TimeframeMap.Past &&
        status !== JobStatusesMap.InProgress &&
        status !== JobStatusesMap.Completed &&
        isActive;

    const canEditDescription = isBaseJob && status === JobStatusesMap.NotStarted && isActive;

    return {
        description,
        jobId,
        employerId,
        timeframe,
        status,
        isActive,
        isQueued,
        canEnable: true,
        canCancel,
        canEditDescription,
        positionsFilled,
        isBaseJob: JobDetailSelectors.getIsBaseJob(state),
        isFixedTerm,
        isEnabling: JobDetailSelectors.getIsActivatingJob(state),
    };
};

const mapActions = dispatch => ({
    onEnableJob: (employerId, jobId) => dispatch(JobDetailActions.activateJob(employerId, jobId)),
    onEditDescription: (description) => dispatch(JobDetailActions.editJobDescription(description)),
    openCancelJobDialog: () => dispatch(JobDetailActions.setIsCancelJobDialogState(true)),
    onCancelRecurringJob: (recurringJobList, jobId) => dispatch(JobDetailActions.cancelRecurringJob(recurringJobList, jobId)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { employerId, jobId } = stateProps;
    const { onEnableJob, onEditDescription, openCancelJobDialog, onCancelRecurringJob } = dispatchProps;
    return {
        ...stateProps,
        onEditDescription,
        openCancelJobDialog,
        onEnableJob,
        onCancelRecurringJob,
        ...ownProps,
    };
};

export default connect(mapState, mapActions, mergeProps)(withRouter(JobHeader));
