import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Button, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Close, CloudUpload, Description, Lock, LockOpen, Visibility } from "@mui/icons-material";
import { Form, Formik, useField } from "formik";
import placeholder from "assets/img/placeholder.jpg";
import { actions as MetaActions } from "store/modules/meta"
import * as yup from "yup";
import { verifyEmail } from "utils/stringUtils";
import { actions as WorkerDetailActions, selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
import { allowedCustomFieldFiles, CustomFieldTypesObject } from "utils/constant";
// import { setUpdateWorkerData } from "store/modules/workerDetails/actions";
const isMobile = false;
const styles = ({ spacing, palette }) => ({
    modal: {
        padding: isMobile ? spacing(2) : spacing(6),
        paddingBottom: isMobile ? spacing(2) : spacing(1),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },

    title: {
        padding: `${spacing(2)} 0 ${spacing(1)} 0`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        // overflowY: "auto",
        // overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
    formControlLabel: {
        width: "100%",
        marginRight: 0,
        paddingRight: spacing(1),
        "& span": {
            color: palette.text.primary,
            whiteSpace: "nowrap"
        },
        "& .MuiFormControlLabel-label": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
        }
    },
    textField: {
        "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input": {
            display: "block",
            paddingRight: `${spacing(4)} !important`
        },
        "& input": {
            textOverflow: "ellipsis"
        },
        "& label": {
            overflow: "hidden",
            width: "100%"
        }
    }
});

function WorkerProfileTab(props) {
    let worker = useSelector(WorkerDetailSelectors.getWorkerDetails).worker;
    let profileImage = worker && worker.signedProfileImageUrl ? worker.signedProfileImageUrl : "";
    let identificationImage = worker && worker.signedIdentificationImageUrl ? worker.signedIdentificationImageUrl : "";

    const {
        firstName,
        lastName,
        // sin,
        phone,
        email,
        street,
        city,
        region,
        country,
        postalCode,
        customFieldValues,
        dcCustomerNumber,
    } = worker;
    const customFields = customFieldValues?.map(field => ({
        name: field?.customField?.name,
        value: field?.value,
        isRequired: field?.customField?.isRequired,
        preview: field?.signedCustomFileUrl,
        type: field?.customField?.type,
        id: field?.id,
        options: field?.customField?.getCustomFieldsOptions?.map(op => ({
            label: op?.label,
            value: op?.value,
            id: op?.id
        })) || [],
    }))?.sort((a, b) => (a?.type < b?.type ? 1 : a?.type > b?.type ? -1 : 0));

    const { value, setCanEdit } = props;

    const [emailProp] = useState(email ? email : "");
    const [emailValid] = useState(verifyEmail(emailProp));
    const [emailEditable] = useState(false);
    const [emailValidStatus] = useState("");

    const dispatch = useDispatch();
    const { classes } = props;
    const controlPadding = {
        leftColumn: { paddingRight: isMobile ? 0 : 4, textAlign: "left" },
        rightColumn: { paddingLeft: isMobile ? 0 : 4 },
    };

    const TabPanel = props => {
        const { children, value, index, height, ...other } = props;
        return (
            <div
                role="tabpanel"
                style={{ height: height ? height : "100%", paddingTop: 20 }}
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && children}
            </div>
        );
    };

    // const TextMaskCustom = props => {
    //   const { inputRef, ...other } = props;
    //
    //   return (
    //     <MaskedInput
    //       {...other}
    //       // eslint-disable-next-line react/no-find-dom-node
    //       ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
    //       mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
    //       placeholderChar={"\u2000"}
    //     />
    //   );
    // };
    //
    // const handleSetSinEditable = () => {
    //   // checkSin(s);
    //   setSinEditable(!sinEditable);
    // };
    //
    // const handleSetEmailEditable = () => {
    //   dispatch(setUpdateWorkerData("email", null));
    //   // setEmailEditable(!emailEditable);
    //   setEmailProp(email);
    // };

    const onSubmit = data => {
        dispatch(WorkerDetailActions.saveWorkerProfile(data));
        setCanEdit();
    };

    const handleLogoFile = useCallback((file, isProfile) => {
        const item = new Blob([file], { type: file.type });
        item.name = `logo_${file.name}`;
        dispatch(WorkerDetailActions.uploadWorkerProfileImage(item, isProfile));
    }, [dispatch]);

    const handleUploadCustomFieldFile = useCallback((file, callback) => {
        if (allowedCustomFieldFiles.find(a => a.mime === file.type)) {
            const item = new Blob([file], { type: file.type });
            item.name = `worker_${file.name}`;
            dispatch(WorkerDetailActions.uploadCustomFieldFile(item, callback));
        } else {
            dispatch(MetaActions.errorToast("Selected file type is not supported"));
        };
    }, []);

    const RenderUploadTab = () => (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
                Profile Photo
            </Typography>
            <img src={profileImage} alt="Profile picture" onError={(e) => e.target.src = placeholder} style={{ margin: 10, width: "60%" }} width={"80%"} />
            <Button color="secondary" variant="outlined" component="label">
                Update Profile Photo
                <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0], true)} />
            </Button>
        </Grid>
    );
    const RenderDocumentUploadTab = () => (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
                Identity Document
            </Typography>
            <img src={identificationImage} alt="Profile picture" onError={(e) => e.target.src = placeholder} style={{ margin: 10, width: "60%" }} width={"80%"} />
            <Button color="secondary" variant="outlined" component="label">
                Update Document
                <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0], false)} />
            </Button>
        </Grid>
    );

    useEffect(() => {
        profileImage = worker && worker.signedProfileImageUrl ? worker.signedProfileImageUrl : "";
    }, [profileImage]);

    useEffect(() => {
        identificationImage = worker && worker.signedIdentificationImageUrl ? worker.signedIdentificationImageUrl : "";
    }, [identificationImage]);

    const OptionRenderer = ({ field, ...props }) => {
        const [formikProps, meta, handlers] = useField(field?.id?.toString());
        const [customFileFieldPreviews, setCustomFileFieldPreview] = useState([]);

        switch (field?.type) {
            case CustomFieldTypesObject.FILE: {
                const file = customFileFieldPreviews?.find(a => Object.keys(a)?.[0] == field?.id);

                const callback = (url) => {
                    handlers.setValue(url.filepath, true);
                    const index = customFileFieldPreviews?.findIndex(a => Object.keys(a)?.[0] == field?.id);
                    setCustomFileFieldPreview(p => Object.assign([], p, { [index >= 0 ? index : customFileFieldPreviews.length]: { [`${field?.id}`]: { path: url.filepath, preview: url.fileSignedurl } } }));
                };

                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow={"ellipsis"} noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} >{field?.name}</Typography>
                        </Grid>
                        <Grid item xs>
                            {formikProps.value ?
                                <>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={9} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={file?.[`${field?.id}`]?.preview || field.preview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography title={file?.[`${field?.id}`]?.path || field.value} variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {file?.[`${field?.id}`]?.path || field.value}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5} display="flex" justifyContent="center">
                                            <IconButton size="small" href={file?.[`${field?.id}`]?.preview || field.preview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1.5} display="flex" justifyContent="center">
                                            <IconButton size="small" onClick={() => {
                                                handlers.setValue("", true);
                                                const data = [...customFileFieldPreviews];
                                                const index = data?.findIndex(a => Object.keys(a)?.[0] == field?.id);
                                                index >= 0 && data.splice(index, 1);
                                                setCustomFileFieldPreview(data);
                                            }}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </>
                                : <Button color="secondary" startIcon={<CloudUpload />} variant="outlined" component="label">
                                    Select File
                                    <input
                                        type="file"
                                        accept={allowedCustomFieldFiles.map(a => a.mime).join(",")}
                                        hidden
                                        onChange={files => handleUploadCustomFieldFile(files.target.files[0], callback)} />
                                </Button>}
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} >
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.CHECKBOX: {
                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow={"ellipsis"} noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} >{field?.name}</Typography>
                        </Grid>
                        <Grid container paddingLeft={2} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                            {field?.options?.map(option => (
                                <Grid key={option?.id?.toString()} item xs={12} sm={field?.options?.length === 1 ? 12 : 6} overflow={"hidden"}>
                                    <FormControlLabel
                                        title={option.label}
                                        color="primary"
                                        className={classes.formControlLabel}
                                        label={option.label}
                                        onChange={() => {
                                            const data = JSON.parse(formikProps.value || "[]")
                                            if (data?.length) {
                                                const ind = data?.findIndex(a => a == option.id);
                                                if (ind >= 0) data?.splice(ind, 1);
                                                else data.push(option?.id);
                                            } else data?.push(option.id);
                                            handlers.setValue(data?.length ? JSON.stringify(data) : '', true);
                                        }}
                                        checked={JSON.parse(formikProps.value || "[]")?.includes(option.id)}
                                        control={<Checkbox />} />
                                </Grid>
                            ))}
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.RADIO: {
                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow={"ellipsis"} noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} id={field?.name}>{field?.name}</Typography>
                        </Grid>
                        <Grid container paddingLeft={2} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                            <FormControl style={{ width: "100%" }}>
                                <RadioGroup
                                    row
                                    aria-labelledby={field?.name}
                                    {...formikProps}
                                >
                                    {field?.options?.map(option => (
                                        <Grid key={option?.id?.toString()} item xs={12} sm={field?.options?.length === 1 ? 12 : 6}>
                                            <FormControlLabel
                                                title={option.label}
                                                color="primary"
                                                value={option?.id?.toString()}
                                                className={classes.formControlLabel}
                                                label={option.label}
                                                control={<Radio />} />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.SELECT: {
                return (
                    <Grid item xs >
                        <TextField
                            select
                            id={field?.name}
                            label={field?.name}
                            className={classes.textField}
                            fullWidth
                            margin="normal"
                            {...formikProps}
                            {...meta}
                            error={meta.error && meta.touched}
                            helperText={
                                meta.error && meta.touched && meta.error
                            }
                        >
                            <MenuItem value={''}><em>Empty</em></MenuItem>
                            {
                                field?.options?.map(op => (
                                    <MenuItem key={op.id} value={op?.id?.toString()}>{op.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                )
            };
            default: {
                return (
                    <>
                        <Grid item xs>
                            <TextField
                                id={field?.name}
                                label={field?.name}
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                {...formikProps}
                                {...meta}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error && meta.touched && meta.error
                                }
                            />
                        </Grid>
                    </>
                )
            };
        };
    };

    // const RenderProfileEditForm = () => (

    // );

    return (
        <TabPanel value={value} index={0}>
            {/* <RenderProfileEditForm /> */}
            <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <Formik
                    initialValues={{
                        email: email || "",
                        dcCustomerNumber: dcCustomerNumber || '',
                        firstName: firstName || '',
                        lastName: lastName || '',
                        postalCode: postalCode || '',
                        phone: phone || '',
                        // sin: sin || '',
                        street: street || '',
                        city: city || '',
                        region: region || '',
                        country: country || '',
                        ...(customFields?.reduce((p, c) => ({ ...p, [`${c?.id}`]: c?.value || "" }), {}) || {}),
                    }}
                    onSubmit={(values, actions) => {
                        onSubmit(values);

                        actions.setSubmitting(false);
                    }}
                    validationSchema={yup.object().shape({
                        email: yup.string().email().required("Required"),
                        firstName: yup.string().required("Required"),
                        lastName: yup.string().required("Required"),
                        postalCode: yup.string().required("Required").max(6, 'Must be exactly 6 character').min(6, 'Must be exactly 6 character'),
                        phone: yup.string().required("Required"),
                        // sin: yup.string().required("Required"),
                        street: yup.string().required("Required"),
                        city: yup.string().required("Required"),
                        region: yup.string().required("Required"),
                        country: yup.string().required("Required"),
                        ...(customFields?.reduce((p, c) => {
                            let yupObj = yup;
                            yupObj = yupObj.string();
                            if (c.type === CustomFieldTypesObject.TEXTBOX) yupObj = yupObj.max(50, `${c.name} must be less 50 characters`);
                            if (c.isRequired) yupObj = yupObj.required(`${c.name} field is required`);

                            const validation = {
                                [`${c?.id}`]: yupObj
                            }
                            return {
                                ...p,
                                ...validation
                            }
                        }, {}) || {})
                    })}>
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleReset,
                        } = props;
                        //   handleFormState(touched);
                        return (
                            <Form style={{ width: "100%" }}>
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    xs={12}
                                    style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={8}
                                        style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                            <TextField

                                                label="FirstName"
                                                name="firstName"
                                                className={classes.textField}
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                error={errors.firstName && touched.firstName}
                                                helperText={errors.firstName && touched.firstName && errors.firstName}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                            <TextField
                                                error={errors.lastName && touched.lastName}

                                                label="LastName"
                                                name="lastName"
                                                className={classes.textField}
                                                value={values.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={errors.lastName && touched.lastName && errors.lastName}
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            direction="column"
                                            style={controlPadding.leftColumn}>
                                            <TextField
                                                id="email"
                                                name="email"

                                                InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                                                className={classes.textField}
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                error={errors.email && touched.email}
                                                helperText={errors.email && touched.email && errors.email}
                                                margin="normal"
                                                label={
                                                    <div style={{ width: "100%", fontSize: 14 }}>
                                                        <div style={{ float: "left", width: "80%" }}>Email Address</div>
                                                        <div
                                                            style={{
                                                                width: "20%",
                                                                textAlign: "right",
                                                                float: "right",
                                                                color: emailValid ? "green" : "red",
                                                                paddingRight: 3,
                                                            }}>
                                                            {emailValidStatus}
                                                        </div>
                                                    </div>
                                                }
                                                type="email"
                                                disabled={!emailEditable}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                // onClick={() =>
                                                                //   emailEditable
                                                                //     ? handleSetEmailEditable()
                                                                //     : handleSetEmailEditable()
                                                                // }
                                                                aria-label="toggle password visibility"
                                                                size="large">
                                                                {emailEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                            <TextField
                                                name="phone"
                                                label="Phone"

                                                error={errors.phone && touched.phone}
                                                className={classes.textField}
                                                value={values.phone}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={
                                                    errors.phone &&
                                                    touched.phone &&
                                                    errors.phone
                                                }
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                            <TextField
                                                name="dcCustomerNumber"

                                                label="DC Number"
                                                error={errors.dcCustomerNumber && touched.dcCustomerNumber}
                                                className={classes.textField}
                                                value={values.dcCustomerNumber}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={
                                                    errors.dcCustomerNumber &&
                                                    touched.dcCustomerNumber &&
                                                    errors.dcCustomerNumber
                                                }
                                                margin="normal"
                                            />
                                        </Grid>

                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={6}
                                            direction="column"
                                            style={controlPadding.rightColumn}>
                                            {/*<TextField
                            id="sin"
                            name="sin"
                            InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                            className={classes.textField}
                            value={values.sin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={errors.sin && touched.sin}
                            helperText={errors.sin && touched.sin && errors.sin}
                            margin="normal"
                            label={
                                <div style={{ width: "100%", fontSize: 14 }}>
                                    <div style={{ float: "left", width: "80%" }}>Social Insurance Number (SIN)</div>
                                    <div
                                        style={{
                                            width: "20%",
                                            textAlign: "right",
                                            float: "right",
                                            color: sinValid ? "green" : "red",
                                            paddingRight: 3,
                                        }}>
                                        {sinValidStatus}
                                    </div>
                                </div>
                            }
                            type="text"
                            // disabled={!sinEditable}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                sinEditable
                                                    ? handleSetSinEditable()
                                                    : handleSetSinEditable()
                                            }
                                        >
                                            {sinEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />*/}
                                            {/*         <NumberFormat
                        id="sin"
                        label={
                          <div style={{ width: "100%", fontSize: 14 }}>
                            <div style={{ float: "left", width: "80%" }}>
                              Social Insurance Number (SIN)
                            </div>
                            <div
                              style={{
                                width: "20%",
                                textAlign: "right",
                                float: "right",
                                color: sinValid ? "green" : "red",
                                paddingRight: 3,
                              }}>
                              {sinValidStatus}
                            </div>
                          </div>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  sinEditable
                                    ? handleSetSinEditable()
                                    : handleSetSinEditable(sinProp)
                                }>
                                {sinEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                        name="sin"
                        className={classes.textField}
                        value={values.sin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={errors.sin && touched.sin}
                        helperText={errors.sin && touched.sin && errors.sin}
                        margin="normal"
                        inputProps={{ min: 1 }}
                        customInput={props => (
                          <TextField
                            {...props}
                            value={values.sin}
                            error={!sinValid}
                            disabled={!sinEditable}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      sinEditable
                                        ? handleSetSinEditable()
                                        : handleSetSinEditable(sinProp)
                                    }>
                                    {sinEditable ? (
                                      <LockOpen style={{ color: "green" }} />
                                    ) : (
                                      <Lock />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        format="###-###-###"
                        mask=""
                      />*/}
                                        </Grid>
                                        <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                            <TextField
                                                name="street"
                                                label="Street"
                                                error={errors.street && touched.street}
                                                className={classes.textField}

                                                value={values.street}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={errors.street && touched.street && errors.street}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                            <TextField
                                                id="city"
                                                name="city"
                                                label="City"

                                                className={classes.textField}
                                                value={values.city}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                error={errors.city && touched.city}
                                                helperText={errors.city && touched.city && errors.city}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid container item xs={12}>
                                            <Grid item xs={12} md={4} style={controlPadding.leftColumn}>
                                                <TextField
                                                    select
                                                    id="region"
                                                    label="Region"
                                                    name="region"

                                                    className={classes.textField}
                                                    value={values.region}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    error={errors.region && touched.region}
                                                    helperText={errors.region && touched.region && errors.region}>
                                                    <MenuItem value="BC">British Columbia</MenuItem>
                                                    <MenuItem value="AB">Alberta</MenuItem>
                                                    <MenuItem value="ON">Ontario</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                md={4}
                                                style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
                                                <TextField
                                                    id="postalCode"

                                                    label="Postal Code"
                                                    error={errors.postalCode && touched.postalCode}
                                                    className={classes.textField}
                                                    value={values.postalCode}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    helperText={errors.postalCode && touched.postalCode && errors.postalCode}
                                                    margin="normal"
                                                // InputProps={{
                                                //   inputComponent: TextMaskCustom,
                                                // }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                                <TextField
                                                    select
                                                    id="country"
                                                    name="country"
                                                    label="Country"

                                                    className={classes.textField}
                                                    value={values.country}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    margin="normal"
                                                    error={errors.country && touched.country}
                                                    helperText={errors.country && touched.country && errors.country}>
                                                    <MenuItem value="Canada">Canada</MenuItem>
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                        {
                                            customFields?.map((field, i) => {
                                                return (
                                                    <Grid
                                                        container
                                                        item
                                                        xs={6}
                                                        key={field?.id?.toString()}
                                                        display="block"
                                                        style={controlPadding[i % 2 === 0 ? "leftColumn" : "rightColumn"]}>
                                                        <OptionRenderer field={field} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                        <RenderUploadTab />
                                        <br />
                                        {/* <RenderDocumentUploadTab /> */}
                                    </Grid>
                                </Grid>
                                <DialogActions className={classes.footer}>
                                    <Button
                                        type="button"
                                        className="outline"
                                        onClick={handleReset}
                                        disabled={!dirty || isSubmitting}>
                                        Reset
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                    // disabled={!_.isEmpty(errors)}
                                    >
                                        Update Profile
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </TabPanel>
    );
}
export default withStyles(styles)(WorkerProfileTab);
