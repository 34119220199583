import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import CategoryTab from "./CategoryTab";
import { tabStyles } from "./styles";
import NotStartedTable from "./tables/NotStartedTable";

class TomorrowTab extends React.Component {
  state = {
    value: 0,
  };

  componentDidMount() {
    if (this.props.history.location.search) {
      const params = new URLSearchParams(this.props.history.location.search);
      const timeframe = parseInt(params.get("timeframe"), 10);
      const listType = parseInt(params.get("listType"), 10);
      if (!isNaN(listType) && timeframe === 1) {
        this.setState({
          value: listType,
        });
        this.handleChange('', listType);
      } else {
        this.handleChange('', this.state.value);
      }
    } else {
      this.handleChange('', this.state.value);
    }
    this.props.fetchStats("Tomorrow");
  }

  handleChange = (event, value) => {
    // this.props.fetchStats("Tomorrow");

    if (value === 0) {
      this.props.fetchTotalJobs();
    } else if (value === 1) {
      this.props.fetchUnfilledJobs();
    } else if (value === 2) {
      this.props.fetchFilledJobs();
    }

    if (this.props.history.location.search) {
      const params = new URLSearchParams(this.props.history.location.search);
      params.set("timeframe", 1);
      params.set("listType", value);
      this.props.history.push(`${this.props.history.location.pathname}?${params.toString()}`);
    }

    this.setState({ value });
  };

  render() {
    const { classes, unfilledTableProps, filledTableProps, totalTableProps } = this.props;

    const tabClass = {
      root: classes.tabRoot,
      selected: classes.tabSelected,
    };
    return (
      <React.Fragment>
        <div style={{ display: "flex" }}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            classes={{ indicator: classes.tabIndicator }}>
            <Tab
              label={
                <CategoryTab
                  title="Total"
                  headerClass={classes.tabHeader}
                  labelClass={classes.tabLabel}
                  {...this.props.total}
                />
              }
              classes={tabClass}
            />
            <Tab
              label={
                <CategoryTab
                  title="Unfilled"
                  headerClass={classes.tabHeader}
                  labelClass={classes.tabLabel}
                  {...this.props.unfilled}
                />
              }
              classes={tabClass}
            />
            <Tab
              label={
                <CategoryTab
                  title="Filled"
                  headerClass={classes.tabHeader}
                  labelClass={classes.tabLabel}
                  {...this.props.filled}
                />
              }
              classes={tabClass}
            />
          </Tabs>
        </div>
        <div className={classes.contentContainer}>
          <SwipeableViews axis="x" index={this.state.value} onChangeIndex={this.handleChangeIndex}>
            <NotStartedTable {...totalTableProps} />
            <NotStartedTable {...unfilledTableProps} />
            <NotStartedTable {...filledTableProps} />
          </SwipeableViews>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = state => {
  const totalTableProps = {
    ...JobSelectors.getTomorrowTotalPaginationData(state),
    data: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "total",
      part: "data",
    }),
    pageFilter: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "total",
      part: "filter",
    }),
    pageSort: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "total",
      part: "sort",
    }),
  };

  const unfilledTableProps = {
    ...JobSelectors.getTomorrowUnfilledPaginationData(state),
    data: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "unfilled",
      part: "data",
    }),
    pageFilter: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "unfilled",
      part: "filter",
    }),
    pageSort: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "unfilled",
      part: "sort",
    }),
  };

  const filledTableProps = {
    ...JobSelectors.getTomorrowFilledPaginationData(state),
    data: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "filled",
      part: "data",
    }),
    pageFilter: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "filled",
      part: "filter",
    }),
    pageSort: JobSelectors.getListData(state, {
      timeframe: "tomorrow",
      status: "filled",
      part: "sort",
    }),
  };

  return {
    unfilledTableProps,
    filledTableProps,
    totalTableProps,
    ...JobSelectors.getListStats(state, "tomorrow"),
  };
};

const mapActions = {
  fetchStats: JobActions.fetchListStats,
  fetchTotalJobs: JobActions.fetchTomorrowTotalJobs,
  onPageChangeTotal: JobActions.fetchTomorrowTotalJobs,
  onSortedChangeTotal: JobActions.setTomorrowTotalSort,
  onFilteredChangeTotal: JobActions.setTomorrowTotalFilter,
  onPageSizeChangeTotal: JobActions.setTomorrowTotalPageSize,

  fetchUnfilledJobs: JobActions.fetchTomorrowUnfilledJobs,
  onPageChangeUnfilled: JobActions.fetchTomorrowUnfilledJobs,
  onSortedChangeUnfilled: JobActions.setTomorrowUnfilledSort,
  onFilteredChangeUnfilled: JobActions.setTomorrowUnfilledFilter,
  onPageSizeChangeUnfilled: JobActions.setTomorrowUnfilledPageSize,

  fetchFilledJobs: JobActions.fetchTomorrowFilledJobs,
  onPageChangeFilled: JobActions.fetchTomorrowFilledJobs,
  onSortedChangeFilled: JobActions.setTomorrowFilledSort,
  onFilteredChangeFilled: JobActions.setTomorrowFilledFilter,
  onPageSizeChangeFilled: JobActions.setTomorrowFilledPageSize,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { unfilledTableProps, filledTableProps, totalTableProps } = stateProps;
  const {
    fetchTotalJobs,
    fetchUnfilledJobs,
    onPageChangeTotal,
    onSortedChangeTotal,
    onFilteredChangeTotal,
    onPageSizeChangeTotal,
    fetchFilledJobs,
    onPageChangeUnfilled,
    onSortedChangeUnfilled,
    onFilteredChangeUnfilled,
    onPageSizeChangeUnfilled,
    onPageChangeFilled,
    onSortedChangeFilled,
    onFilteredChangeFilled,
    onPageSizeChangeFilled,
  } = dispatchProps;
  return {
    ...stateProps,
    ...dispatchProps,
    totalTableProps: {
      ...totalTableProps,
      fetchData: fetchTotalJobs,
      onPageChange: onPageChangeTotal,
      onSortedChange: onSortedChangeTotal,
      onFilteredChange: onFilteredChangeTotal,
      onPageSizeChange: onPageSizeChangeTotal,
    },
    unfilledTableProps: {
      ...unfilledTableProps,
      fetchData: fetchUnfilledJobs,
      onPageChange: onPageChangeUnfilled,
      onSortedChange: onSortedChangeUnfilled,
      onFilteredChange: onFilteredChangeUnfilled,
      onPageSizeChange: onPageSizeChangeUnfilled,
    },
    filledTableProps: {
      ...filledTableProps,
      fetchData: fetchFilledJobs,
      onPageChange: onPageChangeFilled,
      onSortedChange: onSortedChangeFilled,
      onFilteredChange: onFilteredChangeFilled,
      onPageSizeChange: onPageSizeChangeFilled,
    },
    ...ownProps,
  };
};

export default connect(mapState, mapActions, mergeProps)(withStyles(tabStyles)(TomorrowTab));
