import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
import Collapse from "@mui/material/Collapse";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import withStyles from '@mui/styles/withStyles';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Email, ExpandLess, ExpandMore } from "@mui/icons-material";
import avatar from "assets/img/faces/avatar.jpg";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import PerfectScrollbar from "perfect-scrollbar";

import Can from "components/Authorization/Can";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
// import { MenuItem } from "@mui/material";
import { getTimeZone, setTimeZone } from "utils/time";

let ps;

class SidebarWrapper extends React.Component {
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    setSidebarWrapperRef = ref => (this.sidebarWrapper = ref);

    render() {
        // const { className, user, headerLinks, links } = this.props;
        const { className, links } = this.props;
        return (
            <div className={className} ref={this.setSidebarWrapperRef}>
                {/* {user} */}
                {/* {headerLinks} */}
                {links}
            </div>
        );
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            openComponents: this.activeRoute("/components"),
            openForms: this.activeRoute("/forms"),
            openTables: this.activeRoute("/tables"),
            openMaps: this.activeRoute("/maps"),
            openPages: this.activeRoute("-page"),
            timezone: getTimeZone(),
        };
        if (!getTimeZone()) {
            setTimeZone(this.props.timezoneList[0].timezoneName);
        }
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => (this.props.location.pathname.indexOf(routeName) > -1 ? true : false);

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    setMainPanelRef = ref => (this.mainPanel = ref);

    handleChange = e => {
        this.setState({ timezone: e.target.value });
        setTimeZone(e.target.value);
        window.location.reload();
    };

    render() {
        const { classes, color, logo, routes, bgColor, rtlActive } = this.props;
        const itemText = cx(classes.itemText, {
            [classes.itemTextMiniRTL]: rtlActive,
            [classes.itemTextRTL]: rtlActive,
        });

        const collapseItemText = cx(classes.collapseItemText, {
            [classes.collapseItemTextMiniRTL]: rtlActive,
            [classes.collapseItemTextRTL]: rtlActive,
        });

        const userWrapperClass = cx(classes.user, {
            [classes.whiteAfter]: bgColor === "white",
        });

        const caret = cx(classes.caret, {
            [classes.caretRTL]: rtlActive,
        });

        const collapseItemMini = cx(classes.collapseItemMini, {
            [classes.collapseItemMiniRTL]: rtlActive,
        });

        const photo = cx(classes.photo, {
            [classes.photoRTL]: rtlActive,
        });

        const logoMini = cx(classes.logoMini, {
            [classes.logoMiniRTL]: rtlActive,
        });
        const logoClasses = cx(classes.logo, {
            [classes.whiteAfter]: bgColor === "white",
        });
        const drawerPaper = cx(classes.drawerPaper, {
            [classes.drawerPaperRTL]: rtlActive,
        });
        const sidebarWrapper = cx(classes.sidebarWrapper, {
            [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1,
        });
        const user = (
            <div className={userWrapperClass}>
                <div className={photo}>
                    <img src={avatar} className={classes.avatarImg} alt="..." />
                </div>
                <List className={classes.list}>
                    <ListItem className={[classes.item, classes.userItem]}>
                        <NavLink
                            to={"#"}
                            className={`${classes.itemLink} ${classes.userCollapseButton}`}
                            onClick={() => this.openCollapse("openAvatar")}>
                            <ListItemText
                                primary={rtlActive ? "تانيا أندرو" : "Tania Andrew"}
                                secondary={
                                    <b
                                        className={[
                                            caret,
                                            classes.userCaret,
                                            this.state.openAvatar ? classes.caretActive : "",
                                        ]}
                                    />
                                }
                                disableTypography={true}
                                className={[itemText, classes.userItemText]}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            {/* profile links */}
                            <List className={[classes.list, classes.collapseList]}>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink
                                        to={"/dashboard"}
                                        className={`${classes.itemLink} ${classes.userCollapseLinks}`}>
                                        {/* <ListItemIcon>
                                            <Email />
                                        </ListItemIcon> */}
                                        <span className={collapseItemMini}>
                                            <Email />
                                        </span>
                                        <ListItemText
                                            primary={rtlActive ? "ملفي" : "My Settings"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink to="#" className={`${classes.itemLink} ${classes.userCollapseLinks}`}>
                                        <span className={collapseItemMini}>{rtlActive ? "هوع" : "EP"}</span>
                                        <ListItemText
                                            primary={rtlActive ? "تعديل الملف الشخصي" : "Edit Profile"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                                <ListItem className={classes.collapseItem}>
                                    <NavLink to="#" className={`${classes.itemLink} ${classes.userCollapseLinks}`}>
                                        <span className={collapseItemMini}>{rtlActive ? "و" : "S"}</span>
                                        <ListItemText
                                            primary={rtlActive ? "إعدادات" : "Settings"}
                                            disableTypography={true}
                                            className={collapseItemText}
                                        />
                                    </NavLink>
                                </ListItem>
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );

        const links = (
            <List className={classes.list}>
                {routes.map((prop, key) => {
                    if (prop.redirect || prop.hidden) {
                        return null;
                    }
                    if (prop.collapse) {
                        const navLinkClasses = cx(classes.itemLink, {
                            [classes.collapseActive]: this.activeRoute(prop.path),
                        });
                        const itemText = cx(classes.itemText, {
                            [classes.itemTextMiniRTL]: rtlActive,
                            [classes.itemTextRTL]: rtlActive,
                        });
                        const collapseItemText = cx(classes.collapseItemText, {
                            [classes.collapseItemTextMiniRTL]: rtlActive,
                            [classes.collapseItemTextRTL]: rtlActive,
                        });
                        const itemIcon = cx(classes.itemIcon, {
                            [classes.itemIconRTL]: rtlActive,
                        });
                        const caret = cx(classes.caret, {
                            [classes.caretRTL]: rtlActive,
                        });
                        return (
                            <Can key={key} perform={prop.permission}>
                                {isAllowed => {
                                    if (!isAllowed) {
                                        return null;
                                    }

                                    return (

                                        <ListItem key={key} className={classes.item}>
                                            <NavLink
                                                to={window.location.pathname + (window.location.search || '')}
                                                className={navLinkClasses}
                                                onClick={() => this.openCollapse(prop.state)}>
                                                <ListItemIcon className={itemIcon}>
                                                    {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={prop.name}
                                                    secondary={
                                                        <b className={[caret, this.state[prop.state] ? classes.caretActive : ""]} />
                                                    }
                                                    disableTypography={true}
                                                    className={itemText}
                                                />
                                                <div className={classes.expandArrow}>
                                                    {this.state[prop.state] ? <ExpandLess /> : <ExpandMore />}
                                                </div>

                                            </NavLink>
                                            <Collapse in={this.state[prop.state]} unmountOnExit className={classes.collapseMain}>
                                                <List className={cx(classes.list, classes.collapseList)}>
                                                    {prop.views.map((prop, key) => {
                                                        if (prop.redirect) {
                                                            return null;
                                                        }
                                                        const navLinkClasses = cx(classes.collapseItemLink, {
                                                            [" " + classes[color]]: this.activeRoute(prop.path),
                                                        });
                                                        const collapseItemMini = cx(classes.collapseItemMini, {
                                                            [classes.collapseItemMiniRTL]: rtlActive,
                                                        });
                                                        return (
                                                            <Can key={key} perform={prop.permission}>
                                                                {isAllowed => {
                                                                    if (!isAllowed) {
                                                                        return null;
                                                                    }

                                                                    return (
                                                                        <ListItem key={key} className={classes.collapseItem}>
                                                                            <NavLink to={prop.path} className={navLinkClasses}>
                                                                                <span className={collapseItemMini}>{prop.mini}</span>
                                                                                <ListItemText
                                                                                    primary={prop.name}
                                                                                    disableTypography={true}
                                                                                    className={collapseItemText}
                                                                                />
                                                                            </NavLink>
                                                                        </ListItem>)
                                                                }}
                                                            </Can>
                                                        );
                                                    })}
                                                </List>
                                            </Collapse>
                                        </ListItem>
                                    )
                                }}
                            </Can>
                        );
                    }
                    const navLinkClasses = cx(classes.itemLink, {
                        [" " + classes[color]]: this.activeRoute(prop.path),
                    });
                    const itemText = cx(classes.itemText, {
                        [classes.itemTextMiniRTL]: rtlActive,
                        [classes.itemTextRTL]: rtlActive,
                    });
                    const itemIcon = cx(classes.itemIcon, {
                        [classes.itemIconRTL]: rtlActive,
                    });
                    return (
                        <Can key={key} perform={prop.permission}>
                            {isAllowed => {
                                if (!isAllowed) {
                                    return null;
                                }

                                return (
                                    <ListItem className={classes.item}>
                                        <NavLink to={prop.path} className={navLinkClasses}>
                                            <ListItemIcon className={itemIcon}>
                                                {typeof prop.icon === "string" ? <Icon>{prop.icon}</Icon> : <prop.icon />}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={prop.name}
                                                disableTypography={true}
                                                className={itemText}
                                            />
                                        </NavLink>
                                    </ListItem>
                                );
                            }}
                        </Can>
                    );
                })}
                <ListItem className={classes.item}>
                    <NavLink to="/logout" className={classes.itemLink}>
                        <ListItemIcon className={classes.itemIcon}>
                            <Icon>lock</Icon>
                        </ListItemIcon>
                        <ListItemText primary="Logout" disableTypography={true} className={classes.itemText} />
                    </NavLink>
                </ListItem>
            </List>
        );

        const brand = (
            <div className={logoClasses}>
                <a href="/dashboard" target="_blank" rel="noopener noreferrer" className={logoMini}>
                    <img src={logo} alt="logo" className={classes.img} />
                </a>
            </div>
        );

        return (
            <div ref={this.setMainPanelRef}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={rtlActive ? "left" : "right"}
                        open={this.props.open}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"],
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}>
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            headerLinks={<HeaderLinks rtlActive={rtlActive} />}
                            links={links}
                        />
                    </Drawer>
                </Hidden>

                <Hidden mdDown implementation="css">
                    <Drawer
                        anchor={rtlActive ? "right" : "left"}
                        variant="permanent"
                        open={this.props.open}
                        onClose={this.props.handleDrawerToggle}
                        classes={{
                            paper: drawerPaper + " " + classes[bgColor + "Background"],
                        }}>
                        {brand}
                        <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

Sidebar.defaultProps = {
    bgColor: "white",
    color: "green",
    timezoneList: [],
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    timezoneList: PropTypes.array,
};

export default withStyles(sidebarStyle)(Sidebar);
