import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import CategoryTab from "./CategoryTab";
import { tabStyles } from "./styles";
import ActiveTodayTable from "./tables/ActiveTodayTable";
import CompletedTodayTable from "./tables/CompletedTodayTable";
import NotStartedTable from "./tables/NotStartedTable";

class TodayTab extends React.Component {
    state = {
        value: 0,
    };

    componentDidMount() {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            const timeframe = parseInt(params.get("timeframe"), 10);
            const listType = parseInt(params.get("listType"), 10);
            if (!isNaN(listType) && timeframe === 0) {
                this.setState({
                    value: listType,
                });
                this.handleChange('', listType);
            } else {
                this.handleChange('', this.state.value);
            }
        } else {
            this.handleChange('', this.state.value);
        }
        this.props.fetchStats("Today");
    }

    handleChange = (event, value) => {
        if (value === 0) {
            this.props.fetchTotalJobs();
        } else if (value === 1) {
            this.props.fetchActiveJobs();
        } else if (value === 2) {
            this.props.fetchUnfilledJobs();
        } else if (value === 3) {
            this.props.fetchFilledJobs();
        } else if (value === 4) {
            this.props.fetchCompletedJobs();
        }

        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            params.set("timeframe", 0);
            params.set("listType", value);
            this.props.history.push(`${this.props.history.location.pathname}?${params.toString()}`);
        }

        this.setState({ value });
    };

    render() {
        const { classes, unfilledTableProps, filledTableProps, totalTableProps } = this.props;
        const tabClass = {
            root: classes.tabRoot,
            selected: classes.tabSelected,
        };

        return (
            <React.Fragment>
                <div style={{ display: "flex" }}>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        classes={{ indicator: classes.tabIndicator }}>
                        <Tab
                            label={
                                <CategoryTab
                                    title="Total"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    {...this.props.total}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <CategoryTab
                                    title="In Progress"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    {...this.props.active}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <CategoryTab
                                    title="Unfilled"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    {...this.props.unfilled}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <CategoryTab
                                    title="Filled"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    {...this.props.filled}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <CategoryTab
                                    title="Completed"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    {...this.props.completed}
                                />
                            }
                            classes={tabClass}
                        />
                    </Tabs>
                </div>
                <div className={classes.contentContainer}>
                    <SwipeableViews axis="x" index={this.state.value} onChangeIndex={this.handleChangeIndex}>
                        <NotStartedTable {...totalTableProps} />
                        <ActiveTodayTable />
                        <NotStartedTable {...unfilledTableProps} />
                        <NotStartedTable {...filledTableProps} />
                        <CompletedTodayTable />
                    </SwipeableViews>
                </div>
            </React.Fragment>
        );
    }
}

const mapState = state => {
    const totalTableProps = {
        ...JobSelectors.getTodayTotalPaginationData(state),
        data: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "total",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "total",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "total",
            part: "sort",
        }),
    };

    const unfilledTableProps = {
        ...JobSelectors.getTodayUnfilledPaginationData(state),
        data: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "unfilled",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "unfilled",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "unfilled",
            part: "sort",
        }),
    };

    const filledTableProps = {
        ...JobSelectors.getTodayFilledPaginationData(state),
        data: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "filled",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "filled",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "today",
            status: "filled",
            part: "sort",
        }),
    };

    return {
        unfilledTableProps,
        filledTableProps,
        totalTableProps,
        ...JobSelectors.getListStats(state, "today"),
    };
};

const mapActions = {
    fetchStats: JobActions.fetchListStats,

    // Total fetching functions
    fetchTotalJobs: JobActions.fetchTodayTotalJobs,
    onPageChangeTotal: JobActions.fetchTodayTotalJobs,
    onSortedChangeTotal: JobActions.setTodayTotalSort,
    onFilteredChangeTotal: JobActions.setTodayTotalFilter,
    onPageSizeChangeTotal: JobActions.setTodayTotalPageSize,

    // Active fetching functions
    fetchActiveJobs: JobActions.fetchTodayActiveJobs,
    onPageChangeActive: JobActions.fetchTodayActiveJobs,
    onSortedChangeActive: JobActions.setTodayActiveSort,
    onFilteredChangeActive: JobActions.setTodayActiveFilter,
    onPageSizeChangeActive: JobActions.setTodayActivePageSize,

    // Unfilled fetching functions
    fetchUnfilledJobs: JobActions.fetchTodayUnfilledJobs,
    onPageChangeUnfilled: JobActions.fetchTodayUnfilledJobs,
    onSortedChangeUnfilled: JobActions.setTodayUnfilledSort,
    onFilteredChangeUnfilled: JobActions.setTodayUnfilledFilter,
    onPageSizeChangeUnfilled: JobActions.setTodayUnfilledPageSize,

    // Filled fetching functions
    fetchFilledJobs: JobActions.fetchTodayFilledJobs,
    onPageChangeFilled: JobActions.fetchTodayFilledJobs,
    onSortedChangeFilled: JobActions.setTodayFilledSort,
    onFilteredChangeFilled: JobActions.setTodayFilledFilter,
    onPageSizeChangeFilled: JobActions.setTodayFilledPageSize,

    // Completed fetching functions
    fetchCompletedJobs: JobActions.fetchTodayCompletedJobs,
    onPageChangeCompleted: JobActions.fetchTodayCompletedJobs,
    onSortedChangeCompleted: JobActions.setTodayCompletedSort,
    onFilteredChangeCompleted: JobActions.setTodayCompletedFilter,
    onPageSizeChangeCompleted: JobActions.setTodayCompletedPageSize,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { unfilledTableProps, filledTableProps, totalTableProps } = stateProps;
    const {
        fetchTotalJobs,
        onPageChangeTotal,
        onSortedChangeTotal,
        onFilteredChangeTotal,
        onPageSizeChangeTotal,
        fetchUnfilledJobs,
        onPageChangeUnfilled,
        onSortedChangeUnfilled,
        onFilteredChangeUnfilled,
        onPageSizeChangeUnfilled,
        fetchFilledJobs,
        onPageChangeFilled,
        onSortedChangeFilled,
        onFilteredChangeFilled,
        onPageSizeChangeFilled,
    } = dispatchProps;
    return {
        ...stateProps,
        ...dispatchProps,
        totalTableProps: {
            ...totalTableProps,
            fetchData: fetchTotalJobs,
            onPageChange: onPageChangeTotal,
            onSortedChange: onSortedChangeTotal,
            onFilteredChange: onFilteredChangeTotal,
            onPageSizeChange: onPageSizeChangeTotal,
        },
        unfilledTableProps: {
            ...unfilledTableProps,
            fetchData: fetchUnfilledJobs,
            onPageChange: onPageChangeUnfilled,
            onSortedChange: onSortedChangeUnfilled,
            onFilteredChange: onFilteredChangeUnfilled,
            onPageSizeChange: onPageSizeChangeUnfilled,
        },
        filledTableProps: {
            ...filledTableProps,
            fetchData: fetchFilledJobs,
            onPageChange: onPageChangeFilled,
            onSortedChange: onSortedChangeFilled,
            onFilteredChange: onFilteredChangeFilled,
            onPageSizeChange: onPageSizeChangeFilled,
        },
        ...ownProps,
    };
};

export default connect(mapState, mapActions, mergeProps)(withStyles(tabStyles)(TodayTab));
