import { gql } from "@apollo/client";

export default gql`
  query getJobs(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: JobStatuses
    $employerId: String
    $start: DateRangeInput
    $end: DateRangeInput
    $timeframe: Timeframes
    $visibility: JobVisibility
    $like: [JobFilterInput!]
    $order: [JobOrderInput!]
    $filled: Boolean
    $tags: [String!]
    $actionRequired: Boolean
    $isActive: Boolean
    $isFixedTerm: Boolean
    $isQueued: Boolean
    $timezone: String
  ) {
    jobs(
      first: $first
      last: $last
      after: $after
      before: $before
      status: $status
      employerId: $employerId
      start: $start
      end: $end
      timeframe: $timeframe
      visibility: $visibility
      like: $like
      order: $order
      filled: $filled
      tags: $tags
      actionRequired: $actionRequired
      isActive: $isActive
      isQueued: $isQueued
      timezone: $timezone
      isFixedTerm: $isFixedTerm
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          payrollStatus
          employerId
          start
          end
          startUtc
          endUtc
          timezone
          peopleNeeded
          city
          region
          positionsFilled
          checkedInCount
          checkedOutCount
          isPrivate
          tags
          positions {
            id
            isConfirmed
            isReconfirmed
            startShift
            endShift
            payrolls {
              id
              createdAt
              payrollTypeId
              positionId
              taxRegionId
              employmentStandardsRegionId
              rate
              checkIn
              checkOut
              breakDeductionMins
              bonus
              totalUnits
              payableUnits
              payrollType {
                id
                name
                code
              }

              wageEarnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              additionalEarnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              earnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              employeeDeductions {
                id
                payrollId
                amount
                payrollDeductionsTypeId
              }

              employerDeductions {
                id
                payrollId
                amount
                payrollDeductionsTypeId
              }
              totalEmployeeDeductions
              totalEmployerDeductions
              grossWages
              grossEarnings
              netEarnings
              payments {
                id
                paymentProviderReferenceCode
                paymentStatus {
                  code
                  description
                }
              }
            }
          }
          employer {
            id
            companyName
          }
          payroll {
            id
          }
        }
      }
    }
  }
`;
