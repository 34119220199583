/* eslint-disable react/no-find-dom-node */
import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
// import MaskedInput from "react-text-mask";
import { Alert, Avatar, Button as Btn, Button, Checkbox, FormControl, FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Close, CloudUpload, Description, Visibility } from "@mui/icons-material";
import { actions as MetaActions } from "store/modules/meta"
import placeholder from "assets/img/person_placeholder.jpg";
import { Form, Formik, useField } from "formik";
import { profileTabStyles } from "views/Employer/helpers";
import * as yup from "yup";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employerDetails";
import { allowedCustomFieldFiles, CustomFieldTypesObject } from "utils/constant";
const isMobile = false;
const controlPadding = {
    leftColumn: { paddingRight: isMobile ? 0 : 4 },
    rightColumn: { paddingLeft: isMobile ? 0 : 4 },
};

const ProfileTab = props => {
    const dispatch = useDispatch();
    const { classes } = props;
    const [customFileFieldPreviews, setCustomFileFieldPreview] = useState([]);
    let employerr = useSelector(EmployerSelectors.getEmployerDetails).employer;
    let profilePic =
        employerr && employerr.signedProfileImageUrl ? `${employerr.signedProfileImageUrl}` : placeholder;
    let companyLogo = employerr && employerr.signedLogoImageUrl ? `${employerr.signedLogoImageUrl}` : placeholder;
    const {
        companyName,
        title,
        firstName,
        lastName,
        email,
        mobilePhone,
        street,
        city,
        region,
        country,
        postalCode,
        websiteUrl,
        businessPhone,
        customFieldValues
    } = employerr;
    const { handleSetCanEdit } = props;
    const customFields = customFieldValues?.map(field => ({
        name: field?.customField?.name,
        value: field?.value,
        isRequired: field?.customField?.isRequired,
        preview: field?.signedCustomFileUrl,
        type: field?.customField?.type,
        id: field?.id,
        options: field?.customField?.getCustomFieldsOptions?.map(op => ({
            label: op?.label,
            value: op?.value,
            id: op?.id
        })) || [],
    }))?.sort((a, b) => (a?.type < b?.type ? 1 : a?.type > b?.type ? -1 : 0));

    //   const TextMaskCustom = props => {
    //     const { inputRef, ...other } = props;

    //     return (
    //       <MaskedInput
    //         {...other}
    //         ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
    //         mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
    //         placeholderChar={"\u2000"}
    //       />
    //     );
    //   };

    const handleLogoFile = useCallback(file => {
        const item = new Blob([file], { type: file.type });
        item.name = `logo_${file.name}`;
        dispatch(EmployerActions.uploadEmployerLogoImage(item));
    }, []);

    const handleUploadCustomFieldFile = useCallback((file, field, handlers) => {
        if (allowedCustomFieldFiles.find(a => a.mime === file.type)) {
            const item = new Blob([file], { type: file.type });
            item.name = `employer_${file.name}`;

            const callback = (url) => {
                handlers.setValue(url.filepath, true);
                const index = customFileFieldPreviews?.findIndex(a => Object.keys(a)?.[0] == field?.id);
                setCustomFileFieldPreview(p => Object.assign([], p, { [index >= 0 ? index : customFileFieldPreviews.length]: { [`${field?.id}`]: { path: url.filepath, preview: url.fileSignedurl } } }));
            };

            dispatch(EmployerActions.uploadCustomFieldFile(item, callback));
        } else {
            dispatch(MetaActions.errorToast("Selected file type is not supported"));
        };
    }, [customFileFieldPreviews]);

    const handleUploadFile = useCallback(file => {
        const item = new Blob([file], { type: file.type });
        item.name = `profile_${file.name}`;
        dispatch(EmployerActions.uploadEmployerProfileImage(item));
    }, []);

    const RenderUploadTab = () => (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
                Company Logo
            </Typography>
            <img src={companyLogo} className="respo" style={{ margin: 10, width: "60%" }} width={"80%"} />
            <Btn color="secondary" icon={<CloudUpload />} variant="outlined" component="label">
                Update Company Logo
                <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0])} />
            </Btn>

            <Typography
                variant="h6"
                align="left"
                style={{ paddingLeft: 15, marginTop: 10, textAlign: "left" }}>
                Profile Photo
            </Typography>
            <img src={profilePic} alt="" style={{ margin: 10, width: "60%" }} />
            <Btn color="secondary" icon={<CloudUpload />} variant="outlined" component="label">
                Update Profile Image
                <input type="file" hidden onChange={files => handleUploadFile(files.target.files[0])} />
            </Btn>
        </Grid>
    );

    const OptionRenderer = ({ field, ...props }) => {
        const [formikProps, meta, handlers] = useField(field?.id?.toString());

        switch (field?.type) {
            case CustomFieldTypesObject.FILE: {
                const file = customFileFieldPreviews?.find(a => Object.keys(a)?.[0] == field?.id);
                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow="ellipsis" noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} >{field?.name}</Typography>
                        </Grid>
                        <Grid item xs>
                            {formikProps.value ?
                                <>
                                    <Grid container alignItems={"center"} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                                        <Grid xs={9} display="flex" alignItems={"center"} overflow="hidden">
                                            <Avatar src={file?.[`${field?.id}`]?.preview || field.preview} variant="square" sx={{ bgcolor: "grey.400", marginRight: 1 }}>
                                                <Description />
                                            </Avatar>
                                            <Typography title={file?.[`${field?.id}`]?.path || field.value} variant="body2" whiteSpace={"nowrap"} overflow="hidden" textOverflow={"ellipsis"}>
                                                {file?.[`${field?.id}`]?.path || field.value}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1.5} display="flex" justifyContent="center">
                                            <IconButton size="small" href={file?.[`${field?.id}`]?.preview || field.preview} target="_blank">
                                                <Visibility fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={1.5} display="flex" justifyContent="center">
                                            <IconButton size="small" onClick={() => {
                                                handlers.setValue("", true);
                                                const data = [...customFileFieldPreviews];
                                                const index = data?.findIndex(a => Object.keys(a)?.[0] == field?.id);
                                                index >= 0 && data.splice(index, 1);
                                                setCustomFileFieldPreview(data);
                                            }}>
                                                <Close fontSize='small' />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </>
                                : <Btn color="secondary" startIcon={<CloudUpload />} variant="outlined" component="label">
                                    Select File
                                    <input
                                        type="file"
                                        accept={allowedCustomFieldFiles.map(a => a.mime).join(",")}
                                        hidden
                                        onChange={files => handleUploadCustomFieldFile(files.target.files[0], field, handlers)} />
                                </Btn>}
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} >
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.CHECKBOX: {
                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow="ellipsis" noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} >{field?.name}</Typography>
                        </Grid>
                        <Grid container paddingLeft={2} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                            {field?.options?.map(option => (
                                <Grid key={option?.id?.toString()} item xs={12} sm={6} overflow={"hidden"}>
                                    <FormControlLabel
                                        title={option.label}
                                        color="primary"
                                        className={classes.formControlLabel}
                                        label={option.label}
                                        onChange={() => {
                                            const data = JSON.parse(formikProps.value || "[]")
                                            if (data?.length) {
                                                const ind = data?.findIndex(a => a == option.id);
                                                if (ind >= 0) data?.splice(ind, 1);
                                                else data.push(option?.id);
                                            } else data?.push(option.id);
                                            handlers.setValue(data?.length ? JSON.stringify(data) : '', true);
                                        }}
                                        checked={JSON.parse(formikProps.value || "[]")?.includes(option.id)}
                                        control={<Checkbox />} />
                                </Grid>
                            ))}
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.RADIO: {
                return (
                    <>
                        <Grid xs={12} marginTop={2}>
                            <Typography textOverflow="ellipsis" noWrap marginTop={-0.75} color={(meta.error && meta.touched) ? "error" : "default"} id={field?.name}>{field?.name}</Typography>
                        </Grid>
                        <Grid container paddingLeft={2} border={1} borderColor="rgba(0, 0, 0, 0.23)" borderRadius={1} overflow="hidden">
                            <FormControl style={{width:"100%"}}>
                                <RadioGroup
                                    row
                                    aria-labelledby={field?.name}
                                    {...formikProps}
                                >
                                    {field?.options?.map(option => (
                                        <Grid key={option?.id?.toString()} item xs={12} sm={6}>
                                            <FormControlLabel
                                                title={option.label}
                                                color="primary"
                                                value={option?.id?.toString()}
                                                className={classes.formControlLabel}
                                                label={option.label}
                                                control={<Radio />} />
                                        </Grid>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {meta.error && meta.touched && meta.error && <Grid xs={12} marginLeft={2}>
                            <Typography variant="body2" color="error" id={field?.name}>{meta.error}</Typography>
                        </Grid>}
                    </>
                )
            };
            case CustomFieldTypesObject.SELECT: {
                return (
                    <Grid item xs >
                        <TextField
                            select
                            id={field?.name}
                            label={field?.name}
                            className={classes.textField}
                            fullWidth
                            margin="normal"
                            {...formikProps}
                            {...meta}
                            error={meta.error && meta.touched}
                            helperText={
                                meta.error && meta.touched && meta.error
                            }
                        >
                            <MenuItem value={''}><em>Empty</em></MenuItem>
                            {
                                field?.options?.map(op => (
                                    <MenuItem key={op.id} value={op?.id?.toString()}>{op.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                )
            };
            default: {
                return (
                    <>
                        <Grid item xs>
                            <TextField
                                id={field?.name}
                                label={field?.name}
                                className={classes.textField}
                                fullWidth
                                margin="normal"
                                {...formikProps}
                                {...meta}
                                error={meta.error && meta.touched}
                                helperText={
                                    meta.error && meta.touched && meta.error
                                }
                            />
                        </Grid>
                    </>
                )
            };
        };
    };

    const onSubmit = data => {
        dispatch(EmployerActions.saveEmployerProfile(data));
        handleSetCanEdit();
        setCustomFileFieldPreview([]);
    };

    // useEffect(() => {
    //   profilePic = employerr && employerr.signedProfileImageUrl ? employerr.signedProfileImageUrl : "";
    //   companyLogo = employerr && employerr.signedLogoImageUrl ? employerr.signedLogoImageUrl : "";
    // }, [companyLogo, profilePic]);

    return (
        <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Grid container item xs={12} alignItems="flex-start" style={controlPadding.leftColumn}>
                <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
                    Company Profile
                </Typography>

                <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
                    <Formik
                        initialValues={{
                            companyName: companyName || '',
                            title: title || '',
                            firstName: firstName || '',
                            lastName: lastName || '',
                            email: email || '',
                            mobilePhone: mobilePhone || '',
                            street: street || '',
                            city: city || '',
                            region: region || '',
                            country: country || '',
                            postalCode: postalCode || '',
                            websiteUrl: websiteUrl || "",
                            businessPhone: businessPhone || '',
                            ...(customFields?.reduce((p, c) => ({ ...p, [`${c?.id}`]: c?.value || "" }), {}) || {}),
                        }}
                        onSubmit={(values, actions) => {
                            onSubmit(values);
                            actions.setSubmitting(false);
                        }}
                        enableReinitialize
                        validationSchema={yup.object().shape({
                            email: yup.string().email().required("Required"),
                            companyName: yup.string().required("Required"),
                            title: yup.string().required("Required"),
                            firstName: yup.string().required("Required"),
                            lastName: yup.string().required("Required"),
                            mobilePhone: yup.string().required("Required"),
                            postalCode: yup.string().required("Required"),
                            websiteUrl: yup.string().required("Required"),
                            businessPhone: yup.string().required("Required"),
                            street: yup.string().required("Required"),
                            city: yup.string().required("Required"),
                            region: yup.string().required("Required"),
                            country: yup.string().required("Required"),
                            ...(customFields?.reduce((p, c) => {
                                let yupObj = yup;
                                yupObj = yupObj.string();
                                if (c.type === CustomFieldTypesObject.TEXTBOX) yupObj = yupObj.max(50, `${c.name} must be less 50 characters`);
                                if (c.isRequired) yupObj = yupObj.required(`${c.name} field is required`);

                                const validation = {
                                    [`${c?.id}`]: yupObj
                                }
                                return {
                                    ...p,
                                    ...validation
                                }
                            }, {}) || {})
                        })}>
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                dirty,
                                isSubmitting,
                                handleChange,
                                handleBlur,

                                handleReset,
                            } = props;
                            return (
                                <Form style={{width:"100%"}}>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        xs={12}
                                        style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            md={8}
                                            style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                                            <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    name="companyName"
                                                    label="Company Name"
                                                    className={classes.textField}
                                                    value={values.companyName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.companyName && touched.companyName}
                                                    helperText={
                                                        errors.companyName && touched.companyName && errors.companyName
                                                    }
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="title"
                                                    name="title"
                                                    label="Title"
                                                    className={classes.textField}
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.title && touched.title}
                                                    helperText={errors.title && touched.title && errors.title}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    id="firstName"
                                                    name="firstName"
                                                    label="First Name"
                                                    className={classes.textField}
                                                    value={values.firstName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.firstName && touched.firstName}
                                                    helperText={errors.firstName && touched.firstName && errors.firstName}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="lastName"
                                                    name="lastName"
                                                    label="Last Name"
                                                    className={classes.textField}
                                                    value={values.lastName}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.lastName && touched.lastName}
                                                    helperText={errors.lastName && touched.lastName && errors.lastName}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    label=" Email Address"
                                                    disabled
                                                    className={classes.textField}
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.email && touched.email}
                                                    helperText={errors.email && touched.email && errors.email}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="websiteUrl"
                                                    name="websiteUrl"
                                                    label="Website Url"
                                                    placeholder="https://example.com"
                                                    className={classes.textField}
                                                    value={values.websiteUrl}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.websiteUrl && touched.websiteUrl}
                                                    helperText={errors.websiteUrl && touched.websiteUrl && errors.websiteUrl}
                                                    margin="normal"
                                                />
                                            </Grid>

                                            <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    name="mobilePhone"
                                                    label="Mobile Phone"
                                                    className={classes.textField}
                                                    value={values.mobilePhone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.mobilePhone && touched.mobilePhone}
                                                    helperText={
                                                        errors.mobilePhone && touched.mobilePhone && errors.mobilePhone
                                                    }
                                                    disabled
                                                    margin="normal"
                                                />
                                                {/* <NumberFormat
                          id="mobilePhone"
                          name="mobilePhone"
                          label="Mobile Phone"
                          className={classes.textField}
                          value={values.mobilePhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.mobilePhone && touched.mobilePhone}
                          helperText={
                            errors.mobilePhone && touched.mobilePhone && errors.mobilePhone
                          }
                          margin="normal"
                          customInput={TextField}
                          format="+1##########"
                          mask="#"
                        /> */}
                                            </Grid>

                                            <Grid container item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <NumberFormat
                                                    id="businessPhone"
                                                    name="businessPhone"
                                                    label="Business Phone"
                                                    className={classes.textField}
                                                    value={values.businessPhone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.businessPhone && touched.businessPhone}
                                                    helperText={
                                                        errors.businessPhone && touched.businessPhone && errors.businessPhone
                                                    }
                                                    margin="normal"
                                                    customInput={TextField}
                                                    format="+1##########"
                                                    mask="#"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                                <TextField
                                                    name="street"
                                                    label="street"
                                                    error={errors.street && touched.street}
                                                    className={classes.textField}
                                                    value={values.street}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    helperText={errors.street && touched.street && errors.street}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                                <TextField
                                                    id="city"
                                                    name="city"
                                                    label="City"
                                                    className={classes.textField}
                                                    value={values.city}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    fullWidth
                                                    error={errors.city && touched.city}
                                                    helperText={errors.city && touched.city && errors.city}
                                                    margin="normal"
                                                />
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12} md={4} style={controlPadding.leftColumn}>
                                                    <TextField
                                                        select
                                                        id="region"
                                                        label="Region"
                                                        name="region"
                                                        className={classes.textField}
                                                        value={values.region}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        margin="normal"
                                                        error={errors.region && touched.region}
                                                        helperText={errors.region && touched.region && errors.region}>
                                                        <MenuItem value="BC">British Columbia</MenuItem>
                                                        <MenuItem value="AB">Alberta</MenuItem>
                                                        <MenuItem value="ON">Ontario</MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={4}
                                                    style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
                                                    <TextField
                                                        id="postalCode"
                                                        label="Postal Code"
                                                        error={errors.postalCode && touched.postalCode}
                                                        className={classes.textField}
                                                        value={values.postalCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        helperText={
                                                            errors.postalCode && touched.postalCode && errors.postalCode
                                                        }
                                                        margin="normal"
                                                    // InputProps={{
                                                    //   inputComponent: TextMaskCustom,
                                                    // }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                                    <TextField
                                                        select
                                                        id="country"
                                                        name="country"
                                                        label="Country"
                                                        className={classes.textField}
                                                        value={values.country}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        fullWidth
                                                        margin="normal"
                                                        error={errors.country && touched.country}
                                                        helperText={errors.country && touched.country && errors.country}>
                                                        <MenuItem value="Canada">Canada</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                            {
                                                customFields?.map((field, i) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            item
                                                            xs={6}
                                                            key={field?.id?.toString()}
                                                            display="block"
                                                            style={controlPadding[i % 2 === 0 ? "leftColumn" : "rightColumn"]}>
                                                            <OptionRenderer field={field} />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                            <RenderUploadTab />
                                        </Grid>
                                    </Grid>
                                    <DialogActions className={classes.footer}>
                                        <Button
                                            type="button"
                                            className="outline"
                                            onClick={handleReset}
                                            disabled={!dirty || isSubmitting}>
                                            Reset
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            type="submit"
                                        // disabled={!_.isEmpty(errors)}
                                        >
                                            Update Profile
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default profileTabStyles(ProfileTab);
