import Auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import ApolloClient from "utils/apollo";
import history from "utils/history";
import { verifyEmail, verifyPassword } from "utils/stringUtils";
import { actions as MetaActions } from "store/modules/meta";
import FetchMeQuery from "./graphql/queries/fetch-me";
import FetchTimezoneQuery from "./graphql/queries/fetch-timezone";
import types from "./types";

const REDIRECT_URI = `${window.location.origin}/callback`;
const auth0 = new Auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
    redirectUri: REDIRECT_URI,
});
// auth0.crossOriginVerification(); http://*.grizzlyforce.com
const loginParams = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    realm: process.env.REACT_APP_AUTH0_REALM,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};
export const resetForm = () => ({ type: types.RESET });
export const forgetUser = () => ({ type: types.FORGET_USER });
export const clearCredentials = () => ({ type: types.CLEAR_CREDENTIALS });
export const setCredentials = auth => ({ type: types.SET_CREDENTIALS, payload: { auth } });
export const setMe = me => ({ type: types.SET_ME, payload: { me } });
export const setEmail = email => ({ type: types.SET_LOGIN_EMAIL, payload: { email } });
export const setPassword = password => ({ type: types.SET_PASSWORD, payload: { password } });
export const setIsCheckingSession = isCheckingSession => ({
    type: types.IS_CHECKING_SESSION,
    payload: { isCheckingSession },
});

const handleAuth0Error = dispatch => e => {
    if (e.error === "login_required") {
        history.replace("/");
        return;
    }

    let message = `Error: ${e}`;
    if (e.error && (e.errorDescription || e.error_description)) {
        const description = e.errorDescription ? e.errorDescription : e.error_description;
        message = `Error: ${e.error} - ${description}`;
    }

    dispatch(MetaActions.errorToast(message));
};

export const checkSession = () => dispatch => {
    return new Promise((resolve, reject) => {
        auth0.checkSession({ redirectUri: REDIRECT_URI, ...loginParams }, (err, auth) => {
            if (err) {
                return reject(err);
            }

            if (!auth || !auth.idToken) {
                return reject(err);
            }

            resolve(auth);
        });
    })
        .then(auth => {
            // const { permissions } = jwtDecode(auth.accessToken);
            // if (permissions.length === 0) {
            //     history.replace("/");
            //     return dispatch(
            //         MetaActions.infoToast("Your account does not have any permissions set."),
            //     );
            // }
            const roles = jwtDecode(auth.accessToken)[process.env.REACT_APP_ROLES_ENDPOINT];
            if (!roles.includes("SuperAdmin") && !roles.includes("Admin") && !roles.includes("SupportAdmin") && !roles.includes("FinanceAdmin")) {
                history.replace("/");
                return dispatch(MetaActions.infoToast("Not Authorized!"));
            }
            dispatch(setCredentials(auth));
            return auth;
        })
        .then(auth => {
            return ApolloClient.query({
                query: FetchMeQuery,
                variables: { authId: auth?.idTokenPayload?.sub },
            });
        })
        .then(({ data: me }) => me.me)

        .then(me => {
            dispatch(setMe(me));
        })
        .catch(handleAuth0Error(dispatch));
};

export const handleAuthentication = () => dispatch => {
    return new Promise((resolve, reject) => {
        auth0.parseHash((err, auth) => {
            if (err) {
                return reject(err);
            }

            if (!auth || !auth.idToken) {
                return reject(err);
            }

            resolve(auth);
        });
    })
        .then(auth => {
            const roles = jwtDecode(auth.accessToken)[process.env.REACT_APP_ROLES_ENDPOINT];
            if (!roles.includes("SuperAdmin") && !roles.includes("Admin") && !roles.includes("SupportAdmin") && !roles.includes("FinanceAdmin")) {
                history.replace("/");
                return dispatch(MetaActions.infoToast("Not Authorized!"));
            }
            dispatch(setCredentials(auth));
            return auth;
        })
        .then(auth => {
            return ApolloClient.query({
                query: FetchMeQuery,
                variables: {
                    authId: auth?.idTokenPayload?.sub,
                },
            });
        })
        .then(({ data: me }) => me.me)
        .then(me => {
            dispatch(setMe(me));

            history.replace("/dashboard");
        })
        .catch(e => {
            let message = `Error: ${e}`;
            if (e.error && (e.errorDescription || e.error_description)) {
                const description = e.errorDescription ? e.errorDescription : e.error_description;
                message = `Error: ${e.error} - ${description}`;
            }
            history.replace("/");
            dispatch(MetaActions.errorToast(message));
        });
};

export const login = () => (dispatch, getState) => {
    const { email, password } = getState().login;
    const body = { ...loginParams, username: email, password: password };
    if (!verifyPassword(password)) {
        dispatch(MetaActions.errorToast("Invalid Password"));
        return;
    }

    if (!verifyEmail(email)) {
        dispatch(MetaActions.errorToast("Invalid Email Address"));
        return;
    }

    auth0.login(body, err => {
        if (err) {
            dispatch(MetaActions.errorToast(`Error: ${err.error} - ${err.error_description}`));
        }
    });
};

export const sendPasswordReset = () => (dispatch, getState) => {
    const body = { email: getState().login.email, connection: process.env.REACT_APP_AUTH0_REALM };
    return new Promise((resolve, reject) => {
        auth0.changePassword(body, (err, resp) => {
            if (err) {
                return reject(`Error: ${err.description}`);
            }

            return resolve(resp);
        });
    })
        .then(resp => {
            dispatch(MetaActions.successToast(resp));
        })
        .catch(err => {
            dispatch(MetaActions.errorToast(`Error: ${err.description}`));
        });
};

export const logout = () => async dispatch => {
    await auth0.logout({ clientID: process.env.REACT_APP_AUTH0_CLIENT_ID, returnTo: window.location.origin });
    window.localStorage.setItem("logout", Date.now());
    window.localStorage.removeItem("persist:root");
    window.localStorage.removeItem("persist:finance");
    window.localStorage.removeItem("persist:subscriptions");
    dispatch(resetForm());
};

export const setTimezoneList = () => dispatch =>
    ApolloClient.query({ query: FetchTimezoneQuery })
        .then(({ data }) => dispatch({ type: types.SET_TIMEZONE_LIST, payload: data.timezones }))
        .catch(e => console.log(`getTimezoneList error: ${e}`));
