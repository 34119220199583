import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import moment from "moment";

export function getFormView(state) {
    return state.form.view.data;
}

export function getFormEdit(state) {
    return state.form.edit.data;
}

export function getHasChanged(state) {
    return state.form.edit.changed;
}

export const getEmployerCustomRates = state => {
    return state.employerDetails.employerCustomRates || [];
};

export const getEmployerEditingCustomRates = state => {
    return { ...state.employerDetails.editEmployerCustomRateData } || {};
};

export const getEmployerDetails = state => {
    return state.employerDetails;
};
export const getEmployer = state => {
    return state.employerDetails.employer;
};

export const getEmployerDocumentTypes = state => {
    return state?.employerDetails?.employer?.employerDocumentTypes || [];
};

export const getPreferredListData = state => {
    return state.employerDetails.workerExceptions.preferred;
};

export const getBannedListData = state => {
    return state.employerDetails.workerExceptions.banned;
};

export const getJobsListData = state => {
    return state.employerDetails.jobs;
};
export const getManagersList = state => {
    return state.employerDetails.managers;
};

export const getWorkerPickerListData = state => {
    return state.employerDetails.workerPicker;
};

export const getWorkerExceptionsListData = (state, { list, part }) => {
    return state.employerDetails.workerExceptions[list][part];
};

export const getJobsPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.employerJobs)];
};

export const getPreferredWorkersPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.preferredWorkers)];
};

export const getBannedWorkersPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.bannedWorkers)];
};

export const getWorkerPickerPaginationData = state => {
    return state.employerDetails[pagination.getPageInfoAttr(paginationSettings.workerPicker)];
};


export const getTimeBasePremiumListData = (state) => {
    return state.employerDetails.timeBasePremiumList;
};

export const getSelectedTimeBasePremium = (state) => {
    return state.employerDetails.selectedTimeBasePremium;
  };

export const getTimeBasePremiumForm = state => {
    return state.employerDetails.timeBasePremiumForm || {};
};

export const canAddUpdateTimeBasePremium = state => {
    const { startTime, endTime, type, value, days } = state.employerDetails.timeBasePremiumForm || {};
    return (
        startTime &&
        endTime &&
        moment(startTime).add(1,"hour").isSameOrBefore(moment(endTime)) &&
        ((type === "PERCENTAGE" && value > 0 && value <= 100) || (type === "VALUE" && value > 0)) &&
        days && days.length
    )
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.employerDetails.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsTimeBaseListLoading = getLoadingStateFactory("timeBasePremiumList");
export const getIsAddTimeBasePremiumListLoading = getLoadingStateFactory("addTimeBasePremium");
export const getIsRemoveTimeBasePremiumLoading = getLoadingStateFactory("removeTimeBasePremium");


// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.employerDetails.dialog[loader] || false;
};

export const GetNewTimeBasePremiumDialog = getDialogStateFactory("newTimeBasePremium");
export const GetRemoveTimeBasePremiumDialog = getDialogStateFactory("removeTimeBasePremium");

