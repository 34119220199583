import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import moment from "moment";
import types from "./types";

const initialListState = {
    sort: [],
    filter: [],
    data: [],
};

const initialLoadingState = {
    page: true,
    actions: false,
    employerNotes: false,
    timeBasePremiumList: false,
    addTimeBasePremium: false,
    removeTimeBasePremium: false
};
const initialDialogState = {
    newTimeBasePremium: false,
    removeTimeBasePremium: false
};
const initialTimeBasePremiumForm = {
    isForFullShift: false,
    startTime: moment(),
    endTime: moment().add(4, "hours"),
    type: "PERCENTAGE",
    value: 0,
    days: []
};

const initialState = {
    //   editProfile: { ...initialEditProfileState },
    loading: { ...initialLoadingState },
    employer: {},
    dialog: { ...initialDialogState },
    timeBasePremiumList: [],
    selectedTimeBasePremium: null,
    timeBasePremiumForm: { ...initialTimeBasePremiumForm },
    managers: [],
    updateEmployerData: {},
    updateEmployerCustomRateData: {},
    editEmployerCustomRateData: {},
    newEmployerCustomRate: {},
    canUpdateEmployer: false,
    hoursTrendData: [],
    employerCustomRates: [],
    jobs: { ...initialListState, sort: [{ id: "start", desc: true }] },
    workerExceptions: {
        banned: { ...initialListState },
        preferred: { ...initialListState },
    },
    employerNotes: "",
    workerPicker: { ...initialListState },
    ...pagination.initialState(paginationSettings),
};

const actionsMap = {
    [types.SET_SELECTED_TIMEBASE_PREMIUM]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            selectedTimeBasePremium: data,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_TIME_BASE_PREMIUM_LIST]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            timeBasePremiumList: data
        };
    },
    [types.SET_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            timeBasePremiumForm: {
                ...state.timeBasePremiumForm,
                [key]: value
            }
        };
    },
    [types.CLEAR_TIME_BASE_PREMIUM_FORM]: (state) => {
        return {
            ...state,
            timeBasePremiumForm: { ...initialTimeBasePremiumForm },
            selectedTimeBasePremium: null,
        };
    },
    [types.SET_EDIT_TIME_BASE_PREMIUM_FORM]: (state, { payload }) => {
        const { data } = payload;
        return {
            ...state,
            timeBasePremiumForm: data ? {
                isForFullShift: data.isForFullShift,
                startTime: moment(data.startTime, "hh:mm:ss"),
                endTime: moment(data.endTime, "hh:mm:ss"),
                type: data.type,
                value: data.value,
                days: data.days
            } : { ...initialTimeBasePremiumForm }
        };
    },
    [types.RESET]: () => {
        return { ...initialState };
    },
    [types.SET_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return { ...state, updateEmployerData: { ...state.updateEmployerData, [field]: value } };
    },

    [types.SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return {
            ...state,
            editEmployerCustomRateData: { ...state.editEmployerCustomRateData, [field]: value },
        };
    },

    [types.SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return {
            ...state,
            updateEmployerCustomRateData: { ...state.updateEmployerCustomRateData, [field]: value },
        };
    },

    [types.SET_NEW_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
        const { field, value } = payload;
        return { ...state, newEmployerCustomRate: { ...state.newEmployerCustomRate, [field]: value } };
    },

    [types.CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
        return {
            ...state,

            newEmployerCustomRate: {},
        };
    },
    [types.CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
        return {
            ...state,
            updateEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
            editEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
        };
    },
    [types.CLEAR_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
        return {
            ...state,
            canUpdateEmployer: false,
            updateEmployerData: { ...payload },
        };
    },

    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },

    [types.ADD_CHANGE]: (state, { payload }) => {
        const newForm = { ...state.data };
        newForm[payload.fieldName] = payload.fieldValue;
        return {
            ...state,
            editProfile: { ...state.editProfile, changed: true, data: payload },
        };
    },

    [types.SET_UP_EDIT_FORM]: (state, { payload }) => {
        return {
            ...state,
            editProfile: { ...state.editProfile, changed: true, data: payload },
        };
    },

    [types.EDIT_FORM_PENDING]: state => {
        return {
            ...state,
            editProfile: { ...state.editProfile, status: types.EDIT_FORM_PENDING },
        };
    },

    [types.EDIT_FORM_SUCCESS]: (state, { payload }) => {
        return {
            ...state,
            editProfile: {
                ...state.editProfile,
                changed: false,
                data: payload.form,
                status: types.EDIT_FORM_SUCCESS,
            },
        };
    },

    [types.SET_EMPLOYER_CUSTOM_RATES]: (state, { payload }) => {
        return {
            ...state,
            employerCustomRates: [...payload],
        };
    },

    [types.SET_EMPLOYER]: (state, { payload }) => {
        const { employer } = payload;
        return {
            ...state,
            employer,
            //   updateEmployerData: { ...employer },
        };
    },
    [types.SET_MANAGER]: (state, { payload }) => {
        const { managers } = payload;
        return {
            ...state,
            managers,
        };
    },

    [types.SET_HOURS_TREND_DATA]: (state, { payload }) => {
        const { hoursTrendData } = payload;
        return {
            ...state,
            hoursTrendData,
        };
    },
    [types.SET_WORKER_EXCEPTIONS_LIST_DATA]: (state, { payload }) => {
        const { data, list, part } = payload;

        return {
            ...state,
            workerExceptions: {
                ...state.workerExceptions,
                [list]: {
                    ...state.workerExceptions[list],
                    [part]: data,
                },
            },
        };
    },
    [types.SET_JOBS_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;

        return {
            ...state,
            jobs: {
                ...state.jobs,
                [part]: data,
            },
        };
    },

    [types.SET_WORKER_PICKER_LIST_DATA]: (state, { payload }) => {
        const { data, part } = payload;

        return {
            ...state,
            workerPicker: {
                ...state.workerPicker,
                [part]: data,
            },
        };
    },
    [types.SET_EMPLOYER_NOTE]: (state, { payload }) => {
        return {
            ...state,
            employerNotes: payload,
        };
    },
    [types.RESET_EMPLOYER_NOTE]: state => {
        return {
            ...state,
            employerNotes: "",
        };
    },
    ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
