import { gql } from "@apollo/client";

export default gql`
  fragment JobFields on Job {
    id
    payrollStatus
    createdAt
    createdAtUtc
    employerId
    start
    end
    startUtc
    endUtc
    timezone
    workTypeId
    peopleNeeded
    hourlyRate
    street
    unit
    floor
    locationInstruction
    city
    region
    description
    status
    timeframe(timezone: $timezone)
    durationMins
    positionsFilled
    durationMins
    estimatedCost
    isActive
    isFixedTerm
    referenceJobId
    isQueued
    breakMins
    paidBreakMins
    visibility
    notifyEmployer
    isBreakRequired
    isPrivate
    tags
    skills
    gears
    isRecurring
    jobRecurring{
      id
      frequency
      frequencyEndAt
      dayOrWeekDay
      frequencyInterval
    }
    jobManager{
      id
      role
      managerId
      manager{
        id
        firstName
        lastName
        signedProfileImageUrl
      }
    }
    jobAnnouncement{
      id
      title
      employerId
      managerId
      description
      frequency
      dayOrWeekDay
      jobAnnouncementTime{
        sendAt
      }
      workers{
        workerId
      }
      employer{
        id
        fullName
      }
      manager{
        id
        firstName
        lastName
      }
    }
    workType {
      id
      label
    }
    banned {
      workerId
    }
    preferred {
      workerId
    }
    notificationLog {
      id
      workerRating
      totalSuccess
      totalFailed
      sentOn
      sentFrom
    }
  }
`;
